<script>
import Layout from "../../layouts/main";
import * as moment from "moment";
import DatePicker from "vue2-datepicker";
import PaymentMethodView from '../../../components/payment-method-view.vue';
import InvoicePdfPreview from './invoice-pdf-preview.vue';
import { required, maxValue } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import {
	FETCH_INVOICES_DETAILS,
    FETCH_MERCHANT_ENTITY_DETAILS,
    INVOICES_REMINDER,
    INVOICE_VOID,
    INVOICE_UNVOID,
    MARK_SCHEDULE_SENT,
    FETCH_REFUND_DATA_FOR_TRANSACTION,
    CREATE_REFUND_FOR_TRANSACTION,
    INVOICE_TIMELINE
} from "@/state/actions.type";

const greaterThanZero = (value) => value > 0;

export default {
    components: {
        Layout,
        DatePicker,
        InvoicePdfPreview,
        PaymentMethodView,
    },
    data() {
        return {
            isFetching: false,
            pageRef:"",
            scheduleDate:"",
            dueDate:"",
            invoiceCreateDate:"",
            scheduleDateSet:false,
            pdfHeight:"",
            orderRef: "",
            refundData: {},
            isCreatingRefund: false,
            form: {
                amount: "",
                refund_reason: "",
                description: "",
                channel: "",
                refund_type: ""
            },
            refundReasons: [
                { text: 'Duplicate', value: 'duplicate' },
                { text: 'Fraudulent', value: 'fraudulent' },
                { text: 'Requested By Customer', value: 'customer_request' },
                { text: 'Other', value: 'other' }
            ],
            refundReasonsObj: {
                duplicate: 'Duplicate',
                fraudulent: 'Fraudulent',
                customer_request: 'Requested By Customer',
                other: 'Other'
            }
        }
    },
    validations() {
        return {
            form: {
            amount: {
                required,
                maxValue: maxValue(this.refundData.amountToRefund),
                greaterThanZero
            },
            refund_reason: {
                required
            },
            description: {}
            }
        }
    },
    computed: {
        invoicesDetailsDataFromStore() {
             return this.$store.state.invoices.detailsContent || {};
        },
        invoicesTimeLineDataFromStore() {
             return this.$store.state.invoices.timeLineContent || [];
        },
        rows() {
            return this.$store.state.invoices.total_elements;
        },
        isMasterMerchant() {
            return this.$store.state.auth.currentUser.is_master_merchant;
        },
        userPermissions() {
            return this.$store.getters["auth/getUserPermissionsList"];
        },
    },
    created() {
        this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`)
        this.pageRef = this.$route.query.ref;
        this.fetchInvoicesDetails();
        this.fetchInvoicesTimeline();
        // let queryParams = new URLSearchParams(window.location.search);
        // this.orderRef = queryParams.get("order_ref");

    },
    watch: {
        invoicesDetailsDataFromStore() {
            this.scheduleDate = this.invoicesDetailsDataFromStore.scheduled_at ? moment(this.invoicesDetailsDataFromStore.scheduled_at).format('DD-MM-YYYY HH:mm')  : ''
            this.dueDate = this.invoicesDetailsDataFromStore.due_date
            this.invoiceCreateDate = this.invoicesDetailsDataFromStore.created_at
            this.pdfHeight = this.$refs.pdfPreviewHeight && this.$refs.pdfPreviewHeight.clientWidth ? this.$refs.pdfPreviewHeight.clientWidth * 1.41 : ''
        },
    },
    filters: {
        date(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        time(value) {
            return moment(value).format("hh:mm A");
        },
        timeLineDate(value){
            return moment(value).format("DD MMM YYYY, hh:mm A");
        }
    },
    methods: {
        fetchInvoicesDetails() {
            this.$store.dispatch(`invoices/${FETCH_INVOICES_DETAILS}`, {
                ref: this.pageRef,
                submerchantKey: this.$route.query.key
            }).then((response) => {
                this.orderRef = response.content.success_txn_ref;
                if(response.content.status == 'Paid Late' || response.content.status == 'Paid' || response.content.status == 'Partially Refunded'){
                    this.fetchRefundData();   
                }
            });
        },
        fetchInvoicesTimeline(){
            this.$store.dispatch(`invoices/${INVOICE_TIMELINE}`, {
                invoice_ref : this.pageRef,
                submerchantKey: this.$route.query.key
            })
        },
        timelineDisplayName(val){
            if(val == 'INVOICE_CREATED'){
                return 'Invoice created'
            }else if(val == 'INVOICE_SCHEDULED' || val == 'INVOICE_SENT'){
                return 'Invoice sent/scheduled'
            }else if(val == 'INVOICE_REMINDER_SENT'){
                return 'Invoice reminder sent'
            }else if(val == 'INVOICE_PAID'){
                return 'Invoice paid'
            }else if(val == 'INVOICE_PAYMENT_STARTED'){
                return 'Payment started'
            }else if(val == 'INVOICE_PAYMENT_FAILED'){
                return 'Payment failed'
            }else if(val == 'INVOICE_PAYMENT_SUCCESS'){
                return 'Payment succeeded'
            }else if(val == 'INVOICE_REFUNDED'){
                return 'Invoice refunded'
            }else if(val == 'INVOICE_PARTIALLY_REFUNDED'){
                return 'Invoice partially refunded'
            }else if(val == 'INVOICE_UNVOIDED'){
                return 'Invoice Re-opened'
            }else if(val == 'INVOICE_VOIDED'){
                return 'Invoice Voided'
            }else{
                return val
            }
        },
        gobackChannel() {
            this.$router.push("/invoices");
        },
        timelineSendToRefundPage(val) {
            var submerchant_key = this.$route.query.key
            this.$router.push(`/transactions/payments/payment-details/refund?order_ref=${val}&key=${this.isMasterMerchant ? submerchant_key : ""}`);
        },
        timelineSendToDetailsPage(val){
            var submerchant_key = this.$route.query.key
            this.$router.push(`/transactions/payments/payment-details?order_ref=${val}&key=${this.isMasterMerchant ? submerchant_key : ""}`);
        },
        resetAdvanceFilters() {

        },
        downloadPdf(val) {
             window.open(val, '_blank');
        },
        convertReminderDate(reminder, due){
            const daysDiff = moment(due).diff(moment(reminder), 'days',true);
            if(Math.abs(daysDiff) >= 1) {
                let daytext = 'day';
                if(Math.abs(daysDiff) !== 1){
                    daytext = 'days';
                }
                if(daysDiff > 0){
                    return `${daysDiff} ${daytext} before the due date`;
                } else {
                    return `${daysDiff * -1} ${daytext} after the due date`;
                }
            } else {
                const hoursDiff = moment(due).diff(moment(reminder), 'hours',true);

                let timetext = 'Hour';
                if(Math.abs(hoursDiff) !== 1){
                    timetext = 'Hours';
                }
                if(hoursDiff < 0){
                    return `${hoursDiff * -1} ${timetext} after the due date`;
                }else{
                    return `${hoursDiff} ${timetext} before the due date`;
                }
            }
        },
        sendReminder(ref){
            this.$store.dispatch(`invoices/${INVOICES_REMINDER}`, ref)
            .then((response) => {
                console.log(response);
                 setTimeout(() => { 
                    this.fetchInvoicesTimeline();
                 }, 1000);
                this.$notify({
                type: "success",
                text: response.Message ? response.Message : "Reminder Successfully Sended",
                closeOnClick: true,
                });
            })
            .catch((error) => {
                // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: 'Failed To send Reminder.',
                    closeOnClick: true,
                });
            });
        },
        voidInvoice(ref){
            this.$store.dispatch(`invoices/${INVOICE_VOID}`, ref)
            .then((response) => {
                console.log(response);
                this.$notify({
                    type: "success",
                    text: response.message ? response.message : "Invoice Voided Successfully",
                    closeOnClick: true,
                });
                this.fetchInvoicesDetails();
                this.fetchInvoicesTimeline();
            })
            .catch((error) => {
                // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: 'Failed To Voided Invoice.',
                    closeOnClick: true,
                });
            });
        },
        unvoidInvoice(ref){
            this.$store.dispatch(`invoices/${INVOICE_UNVOID}`, ref)
            .then((response) => {
                console.log(response);
                this.$notify({
                    type: "success",
                    text: response.message ? response.message : "Invoice Un-Voided Successfully",
                    closeOnClick: true,
                });
                this.fetchInvoicesDetails();
                this.fetchInvoicesTimeline();
            })
            .catch((error) => {
                // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: 'Failed To Un-Voided Invoice.',
                    closeOnClick: true,
                });
            });
        },
        markScheduleSent(){
            if(this.scheduleDateSet){
                var payload = {
                    scheduled_date:new Date(moment(this.scheduleDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')).toISOString(),
                    invoice_ref : this.pageRef
                } 
            }else{
                payload = {
                    invoice_ref : this.pageRef
                }
            }
            this.$store.dispatch(`invoices/${MARK_SCHEDULE_SENT}`, payload)
            .then((response) => {
                console.log(response);
                this.scheduleDateSet = false;
                this.$notify({
                    type: "success",
                    text: response.Message ? response.Message : "Invoice Sended Successfully",
                    closeOnClick: true,
                });
                this.fetchInvoicesDetails()
                setTimeout(() => { 
                    this.fetchInvoicesTimeline();
                 }, 1000);
            })
            .catch((error) => {
                // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: error.message ? error.message :'Failed To Send Invoice.',
                    closeOnClick: true,
                });
            });
        },
        disabledAfterDueDate(date) {
            // return date > new Date(this.dueDate) || date < new Date(this.invoiceCreateDate);
            return date < new Date().setHours(0,0,0,0) || date > new Date(this.dueDate);
        },
        scheduleDateSetfunc(){
            this.scheduleDateSet = true;   
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

        fetchRefundData() {
            this.$store
            .dispatch(
                `transactions/${FETCH_REFUND_DATA_FOR_TRANSACTION}`,
                this.orderRef
            )
            .then((response) => {
                this.refundData = response;
                this.form.amount = this.refundData ? this.refundData.amountToRefund : 0;

                // if(this.transactionData.channel_key === 'SMARTPAY') {
                //     const isInQueueStatus = this.refundData.refundDetails.find(detail => detail.refund_status === 'IN_QUEUE');
                //     this.showAction(Boolean(isInQueueStatus));
                // }
            });
        },
        resetRefundModal() {
            this.form.refund_reason = 'duplicate';
            this.form.amount = this.refundData.amountToRefund;
            this.form.description = '';
            this.form.channel = '';

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        createRefund() {
            this.$v.form.$touch();

            if (this.$v.form.$anyError) {
                return;
            }

            this.form.channel = this.invoicesDetailsDataFromStore.success_txn_channel_name;

            this.isCreatingRefund = true;

            if(this.form.amount === this.refundData.amountToRefund) {
                this.form.refund_type = 'full';
            } else {
                this.form.refund_type = 'partial';
            }
            this.$store
                .dispatch(`transactions/${CREATE_REFUND_FOR_TRANSACTION}`, {
                order_ref: this.orderRef,
                refundData: this.form
                })
                // eslint-disable-next-line no-unused-vars
                .then((result) => {
                    console.log(result);
                    this.$bvModal.hide('refund-modal');
                    Swal.fire({
                    title: `<strong class="text-success">${this.$t("views.payments.refund.refund_success")}</strong>`,
                    icon: 'success',
                    html:
                        `
                        <p>${this.$t("common.processing")}</p>
                        <div class="card">
                            <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                <p>${this.$t("views.payments.refund.transaction_id")}</p>
                                <p>${this.$t("views.payments.refund.refund_amount")}</p>
                                </div>
                                <div class="col-md-6">
                                <p>${this.invoicesDetailsDataFromStore.success_merchant_txn_ref}</p>
                                <p>${this.form.amount}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        `,
                    focusConfirm: true,
                    confirmButtonText: 'Done'
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.$notify({
                        type: 'error',
                        text: error.Message.error,
                        closeOnClick: true,
                    });
                })
                .finally(() => {
                    this.fetchRefundData();
                    this.isCreatingRefund = false;
                    this.fetchInvoicesDetails()
                    this.fetchInvoicesTimeline();
                });
            },
    }
}
</script>

<template>
    <Layout>
        <!-- <filters class="my-3" @toggle-filter="resetAdvanceFilters" /> -->
        <!-- <div class="d-flex justify-content-between my-3">
            
        </div> -->
        <div class="menu-content">
            <div class="d-flex" style="gap: 25px; align-items: center;">
                <b-btn style="background-color: #DCDAD5;border-color: #DCDAD5;" class="back-button" @click="gobackChannel">
                    <img src="@/assets/images/arrow_left.svg" alt="back" />
                </b-btn>
                <div style="font-size: 18px;font-weight: 400;line-height: 24px;">
                    # {{invoicesDetailsDataFromStore.merchant_invoice_ref}}
                </div>
                <div
                    class="badge font-size-12"
                    style="padding: 6px 15px;border-radius: 8px;line-height: 24px;"
                    :class="{
                    'sent-status':`${invoicesDetailsDataFromStore.status}` === 'Sent',
                    'due-status':`${invoicesDetailsDataFromStore.status}` === 'Overdue',
                    'draft-status':`${invoicesDetailsDataFromStore.status}` === 'Draft',
                    'paid-late-status':`${invoicesDetailsDataFromStore.status}` === 'Paid Late',
                    'badge-soft-danger':
                    `${invoicesDetailsDataFromStore.status}` === 'Voided',
                    'badge-soft-success': `${invoicesDetailsDataFromStore.status}` === 'Paid',
                    'created-status': `${invoicesDetailsDataFromStore.status}` === 'Created',
                    'badge-soft-warning': `${invoicesDetailsDataFromStore.status}` === 'Scheduled',
                    'badge-orange': invoicesDetailsDataFromStore.status === 'Under Review',
                    'refunded': invoicesDetailsDataFromStore.status === 'Refunded',
                    'partial-refund': invoicesDetailsDataFromStore.status === 'Partially Refunded'
                }">
                    {{ invoicesDetailsDataFromStore.status }}
                </div>      
            </div>
            <div class="d-flex" style="gap: 10px;">
                <b-btn style="padding: 12px 16px; gap: 10px; background: #fff; color: #000; font-size: 14px;border-radius: 12px;" variant="primary" @click="downloadPdf(invoicesDetailsDataFromStore.invoice_url)" class="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                        <path d="M16.44 8.9C20.04 9.21 21.51 11.06 21.51 15.11V15.24C21.51 19.71 19.72 21.5 15.25 21.5H8.73998C4.26998 21.5 2.47998 19.71 2.47998 15.24V15.11C2.47998 11.09 3.92998 9.24 7.46998 8.91M12 2V14.88" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.3499 12.6504L11.9999 16.0004L8.6499 12.6504" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{  $t("common.download") }}
                </b-btn>
                <b-btn @click="markScheduleSent()" variant="primary" style="padding: 12px 16px; gap: 10px; font-size: 14px;border-radius: 12px;" class="d-flex align-items-center">
                    <svg v-if="!scheduleDateSet" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g id="vuesax/bold/send-2"><g id="send-2">
                            <path id="Vector" d="M10.7601 1.97336L4.74008 3.97336C0.693412 5.3267 0.693412 7.53336 4.74008 8.88003L6.52674 9.47336L7.12008 11.26C8.46674 15.3067 10.6801 15.3067 12.0267 11.26L14.0334 5.2467C14.9267 2.5467 13.4601 1.07336 10.7601 1.97336ZM10.9734 5.56003L8.44008 8.1067C8.34008 8.2067 8.21341 8.25336 8.08674 8.25336C7.96008 8.25336 7.83341 8.2067 7.73341 8.1067C7.54008 7.91336 7.54008 7.59336 7.73341 7.40003L10.2667 4.85336C10.4601 4.66003 10.7801 4.66003 10.9734 4.85336C11.1667 5.0467 11.1667 5.3667 10.9734 5.56003Z" fill="white"/>
                        </g></g>
                    </svg>
                    <svg v-if="scheduleDateSet" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g id="vuesax/bold/clock">
                        <g id="clock">
                        <path id="Vector" d="M8.00016 1.33301C4.32683 1.33301 1.3335 4.32634 1.3335 7.99967C1.3335 11.673 4.32683 14.6663 8.00016 14.6663C11.6735 14.6663 14.6668 11.673 14.6668 7.99967C14.6668 4.32634 11.6735 1.33301 8.00016 1.33301ZM10.9002 10.3797C10.8068 10.5397 10.6402 10.6263 10.4668 10.6263C10.3802 10.6263 10.2935 10.6063 10.2135 10.553L8.14683 9.31967C7.6335 9.01301 7.2535 8.33967 7.2535 7.74634V5.01301C7.2535 4.73967 7.48016 4.51301 7.7535 4.51301C8.02683 4.51301 8.2535 4.73967 8.2535 5.01301V7.74634C8.2535 7.98634 8.4535 8.33967 8.66016 8.45967L10.7268 9.69301C10.9668 9.83301 11.0468 10.1397 10.9002 10.3797Z" fill="white"/>
                        </g>
                        </g>
                    </svg>
                    <span v-if="!scheduleDateSet">Send Invoice</span>
                    <span v-if="scheduleDateSet"> Schedule</span> 
                </b-btn>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-7 col-sm-12">
                <div>
                    <div ref="pdfPreviewHeight">
                        <invoice-pdf-preview :invoiceDetails="invoicesDetailsDataFromStore" fromInvoiceDetails  :isMasterMerchant="isMasterMerchant" :style="{ 'min-height':  pdfHeight ? pdfHeight +'px' : 'auto' }" />
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-sm-12">
                <!-- {{invoicesDetailsDataFromStore}} -->
                <div style="display: flex;  justify-content: space-between;">
                    <div>
                        <router-link v-if="!isMasterMerchant" :to="'/invoices/invoice-form?invoiceRef=' + pageRef ">
                            <div style="cursor: pointer;padding: 13px 15px;border-radius: 12px;border: 1px #DCDAD5 solid;font-size: 14px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <g id="Iconsax/Linear/save2" opacity="0.7">
                                    <path id="Vector" opacity="0.7" d="M12.89 5.87988H5.11C4.28599 5.88252 3.49648 6.21103 2.91381 6.79369C2.33114 7.37636 2.00264 8.16587 2 8.98988V20.3499C2 21.7999 3.04 22.4199 4.31 21.7099L8.24 19.5199C8.66 19.2899 9.34 19.2899 9.75 19.5199L13.68 21.7099C14.95 22.4199 15.99 21.7999 15.99 20.3499V8.98988C15.9907 8.58213 15.911 8.17824 15.7556 7.80127C15.6001 7.4243 15.372 7.08164 15.0841 6.79285C14.7962 6.50406 14.4543 6.2748 14.0779 6.11815C13.7014 5.9615 13.2978 5.88054 12.89 5.87988Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_2" opacity="0.7" d="M16 8.98988V20.3499C16 21.7999 14.96 22.4099 13.69 21.7099L9.76 19.5199C9.34 19.2899 8.66 19.2899 8.24 19.5199L4.31 21.7099C3.04 22.4099 2 21.7999 2 20.3499V8.98988C2 7.27988 3.4 5.87988 5.11 5.87988H12.89C14.6 5.87988 16 7.27988 16 8.98988Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_3" d="M22 5.11V16.47C22 17.92 20.96 18.53 19.69 17.83L16 15.77V8.99C16 7.28 14.6 5.88 12.89 5.88H8V5.11C8 3.4 9.4 2 11.11 2H18.89C20.6 2 22 3.4 22 5.11Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                                <span class="text-black" style="padding-left: 10px; cursor: pointer;">Duplicate</span>
                            </div>
                        </router-link>
                    </div>
                    <div v-if="invoicesDetailsDataFromStore.status == 'Created' || invoicesDetailsDataFromStore.status == 'Scheduled' || invoicesDetailsDataFromStore.status == 'Sent' || invoicesDetailsDataFromStore.status == 'Overdue'|| invoicesDetailsDataFromStore.status == 'Paid Late'|| invoicesDetailsDataFromStore.status == 'Paid'|| invoicesDetailsDataFromStore.status == 'Partially Refunded' || invoicesDetailsDataFromStore.status == 'Voided'">
                        <b-dropdown class="dropdown_details" size="sm" variant="white" dropleft>
                            <template v-slot:button-content>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g id="Iconsax/Linear/more">
                                <path id="Vector" d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5"/>
                                </g>
                            </svg>
                            </template>
                            <b-dropdown-item-button v-if="invoicesDetailsDataFromStore.status == 'Created' || invoicesDetailsDataFromStore.status == 'Scheduled'"  @click="markScheduleSent()">Mark as Sent</b-dropdown-item-button>
                            <b-dropdown-item-button  v-if="invoicesDetailsDataFromStore.status == 'Created' || invoicesDetailsDataFromStore.status == 'Scheduled' || invoicesDetailsDataFromStore.status == 'Sent' || invoicesDetailsDataFromStore.status == 'Overdue'" @click="voidInvoice(pageRef)">Void</b-dropdown-item-button>
                            <b-dropdown-item-button  v-if="invoicesDetailsDataFromStore.status == 'Voided'" @click="unvoidInvoice(pageRef)">Re-open</b-dropdown-item-button>
                            <b-dropdown-item-button  v-if="(userPermissions.includes('initiate-transaction-refunds')) && (invoicesDetailsDataFromStore.status == 'Paid Late' || invoicesDetailsDataFromStore.status == 'Paid' || invoicesDetailsDataFromStore.status == 'Partially Refunded')" :disabled="!refundData || refundData.amountToRefund <= 0" v-b-modal.refund-modal>Refund</b-dropdown-item-button>
                            <!-- <b-dropdown-item-button  @click="deletePaymentLink(row.item.link_ref)">Delete Invoice</b-dropdown-item-button> -->
                        </b-dropdown>
                    </div>
                </div>
                <div v-if="invoicesDetailsDataFromStore.status == 'Created' || invoicesDetailsDataFromStore.status == 'Scheduled'">
                    <div style="border-top: 1px rgba(220, 218, 213, 0.50) solid; margin-top:15px; margin-bottom:15px;"></div>
                    <label style="font-size: 14px;line-height: 20px;margin-bottom: 14px;">Schedule</label>
                    <date-picker
                        type="datetime"
                        v-model="scheduleDate"
                        format="D MMM, YYYY HH:mm"
                        value-type="DD-MM-YYYY HH:mm"
                        placeholder="DD-MM-YYYY HH:mm"
                        id="scheduled-date"
                        name="scheduled-date"
                        prefix-class="xmx"
                        class="w-100"
                         @input="scheduleDateSetfunc"
                        :disabled-date="disabledAfterDueDate"
                        >
                    </date-picker>
                </div>
                <div v-if="invoicesDetailsDataFromStore.reminder_at && (invoicesDetailsDataFromStore.status == 'Sent' || invoicesDetailsDataFromStore.status == 'Overdue')">
                    <div style="border-top: 1px rgba(220, 218, 213, 0.50) solid; margin-top:15px; margin-bottom:15px;"></div>
                    <div>
                        <label style="font-size: 14px;line-height: 20px;margin-bottom: 14px;display: flex;align-items: center;justify-content: space-between;">
                            Reminders
                            <div @click="sendReminder(pageRef)" style="cursor: pointer;padding: 5px 10px;border-radius: 10px;border: 1px solid rgb(220, 218, 213);font-size: 12px;background: #000;color: #fff;">
                                <span style="cursor: pointer;">Remind Now</span>
                            </div>
                        </label>
                        <div style="border-radius: 12px;border: 1px rgba(220, 218, 213, 0.50) solid;display: flex;padding: 8px 16px 8px 0px;align-items: center;">
                            <div style="border-right: 1px rgba(220, 218, 213, 0.50) solid;padding: 8px 16px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g opacity="0.5">
                                    <path d="M10 5.36719V8.14219" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                                    <path d="M10.0166 1.66602C6.94992 1.66602 4.46658 4.14935 4.46658 7.21602V8.96602C4.46658 9.53268 4.23325 10.3827 3.94158 10.866L2.88325 12.6327C2.23325 13.7244 2.68325 14.941 3.88325 15.341C7.86658 16.666 12.1749 16.666 16.1583 15.341C17.2833 14.966 17.7666 13.6494 17.1583 12.6327L16.0999 10.866C15.8083 10.3827 15.5749 9.52435 15.5749 8.96602V7.21602C15.5666 4.16602 13.0666 1.66602 10.0166 1.66602Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                                    <path d="M12.7751 15.6836C12.7751 17.2086 11.5251 18.4586 10.0001 18.4586C9.24176 18.4586 8.54176 18.1419 8.04176 17.6419C7.54176 17.1419 7.2251 16.4419 7.2251 15.6836" stroke="black" stroke-miterlimit="10"/>
                                    </g>
                                </svg>
                            </div>
                            <div >
                                <div style="padding-left: 16px;font-size: 14px;line-height: 16px;"> {{convertReminderDate(invoicesDetailsDataFromStore.reminder_at, invoicesDetailsDataFromStore.due_date)}} </div>
                                <div style="color:rgba(0, 0, 0, 0.50); font-weight:325; padding-left: 16px;font-size: 12px;line-height: 12px;margin-top: 5px;">On {{invoicesDetailsDataFromStore.reminder_at | date}} at {{invoicesDetailsDataFromStore.reminder_at | time}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="invoicesDetailsDataFromStore.chosen_payment_methods && Object.keys(invoicesDetailsDataFromStore.chosen_payment_methods).length > 0">
                    <div style="border-top: 1px rgba(220, 218, 213, 0.50) solid; margin-top:15px; margin-bottom:15px;"></div>
                    <label style="font-size: 14px;line-height: 20px;margin-bottom: 14px;">Payment Methods</label>
                    <div>
                        <payment-method-view class="col-md-12" hideTitle :selectedPaymentMethods="invoicesDetailsDataFromStore.chosen_payment_methods" />
                    </div>
                </div>
                <div v-if="invoicesDetailsDataFromStore.notes">
                    <div style="border-top: 1px rgba(220, 218, 213, 0.50) solid; margin-top:15px; margin-bottom:15px;"></div>
                    <div>
                        <label style="font-size: 14px; line-height: 20px; margin-bottom: 14px;">Custom Fields</label>
                        <div v-for="(data, index) in invoicesDetailsDataFromStore.notes" :key="index" style="margin-bottom: 8px; border-radius: 12px;border: 1px solid rgba(220, 218, 213, 0.5);display: flex; align-items: center;font-size: 12px;">
                            <div style="padding: 0px 16px;">
                                {{data.key}}
                            </div>
                            <div style="padding: 16px;">
                                <div style="padding-left: 16px; font-size: 12px; line-height: 16px; color: rgba(0, 0, 0, 0.5);">   {{data.value}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div v-if="invoicesTimeLineDataFromStore.length > 0">
                    <div style="border-top: 1px rgba(220, 218, 213, 0.50) solid; margin-top:15px; margin-bottom:15px;"></div>
                    <div>
                        <label style="font-size: 14px; line-height: 20px; margin-bottom: 14px;">Timeline</label>
                            <ul> 
                            <li v-for="(data, index) in invoicesTimeLineDataFromStore" :key="index" class="time-line-container">
                                <div class="svg-container">
                                    <svg v-if="data.event_name == 'INVOICE_PAYMENT_SUCCESS'" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g id="vuesax/linear/tick-circle">
                                    <g id="tick-circle">
                                    <path id="Vector" d="M6 11C8.75 11 11 8.75 11 6C11 3.25 8.75 1 6 1C3.25 1 1 3.25 1 6C1 8.75 3.25 11 6 11Z" stroke="#219653" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_2" d="M3.875 6.00094L5.29 7.41594L8.125 4.58594" stroke="#219653" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    </g>
                                    </svg>

                                    <svg v-else-if="data.event_name == 'INVOICE_PAYMENT_FAILED'" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g id="close-circle">
                                    <path id="Vector" d="M6.00195 11C8.75195 11 11.002 8.75 11.002 6C11.002 3.25 8.75195 1 6.00195 1C3.25195 1 1.00195 3.25 1.00195 6C1.00195 8.75 3.25195 11 6.00195 11Z" stroke="#E36464" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_2" d="M4.58594 7.41594L7.41594 4.58594" stroke="#E36464" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_3" d="M7.41594 7.41594L4.58594 4.58594" stroke="#E36464" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    </svg>

                                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <circle id="Ellipse 10" cx="5" cy="5" r="5" fill="#DCDAD5" fill-opacity="0.7"/>
                                    </svg>
                                </div>
                                <div class="time-line-heading">{{ timelineDisplayName(data.event_name)}} 
                                    <span v-if="data.payload.portone_txn_ref && (data.event_name === 'INVOICE_REFUNDED' || data.event_name === 'INVOICE_PARTIALLY_REFUNDED' || data.event_name === 'INVOICE_PAYMENT_STARTED' || data.event_name === 'INVOICE_PAYMENT_FAILED' || data.event_name === 'INVOICE_PAYMENT_SUCCESS')">
                                        | <span v-if="data.event_name === 'INVOICE_REFUNDED' || data.event_name === 'INVOICE_PARTIALLY_REFUNDED'" style="cursor: pointer;" @click="timelineSendToRefundPage(data.payload.portone_txn_ref)" class="text-chai">{{data.payload.portone_txn_ref}}</span>
                                          <span v-if="data.event_name === 'INVOICE_PAYMENT_STARTED' || data.event_name === 'INVOICE_PAYMENT_FAILED' || data.event_name === 'INVOICE_PAYMENT_SUCCESS'" style="cursor: pointer;" @click="timelineSendToDetailsPage(data.payload.portone_txn_ref)" class="text-chai">{{data.payload.portone_txn_ref}}</span>
                                    </span>
                                </div>
                                <p class="time-line-sub-heading"> {{data.created_at | timeLineDate}}</p>
                            </li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-lg-12">
                <b-modal id="refund-modal" body-class="p-0" title="Create Refund" centered @show="resetRefundModal" @hidden="resetRefundModal" hide-footer no-close-on-backdrop>
                <template #modal-header="{ close }">
                    <!-- Emulate built in modal header close button action -->
                    <h5 class="font-20px text-black">Create Refund</h5>
                    <img @click="close" style="cursor: pointer;" src="@/assets/images/close.svg" alt="close" />
                </template>

                <div class="d-flex justify-content-between p-3 common-bg-30" style="border-bottom: 1px solid #DCDAD5;">
                    <p class="mb-0 collapse-body-table-head-col">Transaction ID</p>
                    <p class="mb-0 collapse-body-table-col">{{ this.invoicesDetailsDataFromStore.success_merchant_txn_ref }}</p>
                </div>
                <div class="d-flex justify-content-between p-3 common-bg-30">
                    <p class="mb-0 collapse-body-table-head-col">Customer Email</p>
                    <p class="mb-0 collapse-body-table-col">{{ invoicesDetailsDataFromStore.customer_email_address }}</p>
                </div>

                <form class="p-3">
                    <b-form-group :label="$t('common.amount')" label-class="collapse-body-table-col">
                    <b-form-input
                        name="amount"
                        type="number"
                        v-model.number="$v.form.amount.$model"
                        :state="validateState('amount')"
                        :max="invoicesDetailsDataFromStore.total_amount"
                        style="padding: 18px 25px !important; height: 44px;"
                    ></b-form-input>
                    <!-- <div v-if="transactionData && transactionData.cross_border_data && transactionData.cross_border_data.forex_details" class="conversion-rates">
                        <span>
                        {{ $v.form.amount.$model ? ($v.form.amount.$model) : 0 }} {{ transactionData.cross_border_data.forex_details.payment_currency }} <img width="12px" height="12px" src="@/assets/images/repeat.jpg" alt="converts" /> {{ (($v.form.amount.$model ? $v.form.amount.$model : 0)/transactionData.cross_border_data.forex_details.forex_rate).toFixed(3) }} {{ transactionData.cross_border_data.forex_details.invoice_currency }} 
                        </span>
                        <span>
                        1 {{ transactionData.cross_border_data.forex_details.invoice_currency }} = {{ transactionData.cross_border_data.forex_details.forex_rate.toFixed(6) }} {{ transactionData.cross_border_data.forex_details.payment_currency }}
                        </span>
                    </div> -->
                    <div class="invalid-feedback" v-if="!$v.form.amount.required">Amount is required.</div>
                    <div class="invalid-feedback" v-else-if="!$v.form.amount.greaterThanZero">Amount must be greater than zero.</div>
                    <div class="invalid-feedback" v-else-if="!$v.form.amount.maxValue">Maximum amount possible is {{ $v.form.amount.$params.maxValue.max }}</div>
                    </b-form-group>

                    <b-form-group :label="$t('views.payments.refund.refund_reason')" label-class="collapse-body-table-col">

                    <b-dropdown variant="white" dropup menu-class="w-100 refund-reason-item" class="w-100 refund-reason">
                        <template v-slot:button-content>
                        {{ refundReasonsObj[form.refund_reason] || "" }}
                        <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                        </template>

                        <b-dropdown-item
                        v-for="(option, i) in refundReasons"
                        :key="`refundReason${i}`"
                        @click="() => form.refund_reason = option.value"
                        :link-class="{'active': option.value === form.refund_reason, 'd-flex': true}"
                        >
                        <div class="my-auto">
                            {{ option.text }}
                        </div>
                        </b-dropdown-item>
                    </b-dropdown>
                    </b-form-group>

                    <b-form-group :label="$t('views.payments.refund.note')" label-class="collapse-body-table-col">
                    <b-form-textarea
                        id="note"
                        v-model="$v.form.description.$model"
                        :placeholder="$t('common.type_here')"
                        :state="validateState('description')"
                        rows="3"
                        max-rows="6"
                        class="textarea-input"
                    ></b-form-textarea>
                    </b-form-group>

                    <div style="gap: 12px;" class="d-flex justify-content-end">
                    <b-button variant="white" size="sm" class="cancel" style="color: #000;" @click="$bvModal.hide('refund-modal')">{{ $t("button.cancel") }}</b-button>
                    <b-button variant="white" size="sm" class="save" @click="createRefund" :disabled="isCreatingRefund">{{ $t("button.save") }}</b-button>
                    </div>
                </form>
                </b-modal>
            </div>
        </div>

    </Layout>
</template>

<style lang="scss">
    #scheduled-date input {
        padding: 13px 25px;
        height: 48px;
        border-radius: 12px;
        border: 1px solid #DCDAD5;
        background: transparent;
        font-size: 14px;
        font-weight: 350;
        color: #000;
    }
    .due-status{
        background: rgba(252, 107, 45, 0.10);
        color: #FC6B2D;
    }
    .draft-status{
        background: rgba(220, 218, 213, 0.50);
        color: rgba(0, 0, 0, 0.70);
    }
    .created-status{
        background: rgba(220, 218, 213, 0.50);
        color: rgba(0, 0, 0, 0.70);
    }
    .select-template-container{
        background: white;
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        position: absolute;
        top: 70px;
        padding: 18px 12px;
        width: 290px;
    }
    .select-template-section{
        border-radius: 8px;
        padding: 10px 12px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .select-template-section:hover {
        background: rgba(220, 218, 213, 0.30);
    }
    .select-template-section .name{
        line-height: 20px;
    }
    .select-template-section .sub-text{
        color: rgba(0, 0, 0, 0.50);
        font-size: 12px;
        font-weight: 325;
        line-height: 20px;
    }
    .select-template-section:hover .name{
        color: #FC6B2D;
    }
    .template-section-img{
        display: none;
    }
    .select-template-section:hover .template-section-img{
        display: block;
    }
    .modal-header-custom{
        border-bottom: none;
        padding-bottom: 0px;
    }
    .modal-body-custom{
        padding: 0px 35px 35px 35px;
    }
    .modal-footer-custom{
        border-top: none;
        padding: 0px 55px 25px 55px;
    }
    .template-section{
        padding: 10px 25px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 2px rgba(220, 218, 213, 0.30) solid;
        margin-top: 25px;
    }
    .template-section .name{
        line-height: 24px;
    }
    .template-section .sub-text{
        color: rgba(0, 0, 0, 0.30);
        font-size: 10px;
        font-family: Gotham;
        font-weight: 325;
        line-height: 12px;
    }
    .template-section:hover{
        background: rgba(220, 218, 213, 0.30);
        border: 2px #DCDAD5 solid;
    }

    .back-button{
        width: 44px;
        height: 44px;
        border-radius: 12px;
        padding: 0 !important;
        background: #dfdedc;
        border: none;
    }
    .dropdown_details button{
        padding: 0px 12px;
        border-radius: 12px;
        border: 1px #DCDAD5 solid;
        height: 44px;
        background: #fff;
        line-height: 18px;
    }
    .dropdown_details ul{
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .dropdown_details ul button{
        padding: 12px 25px;
        border-radius: 0px;
        background-color: transparent;
        height: auto;
        border: transparent;
    }
    .due-status{
        background: rgba(252, 107, 45, 0.10);
        color: #FC6B2D;
    }
    .sent-status{
        background: rgba(57, 131, 172, 0.10);
        color: #3983AC;
    }
    .paid-late-status{
        background:  rgba(155.09, 155.09, 245.68, 0.10);
        color: #7171E0;
    }
    .refunded{
        background: rgba(222, 53, 53, 0.10); 
        color: #DE3535;
    }
    .partial-refund{
        background: rgba(227, 179, 7, 0.10);
        color: #C59900;
    }
    .badge-soft-success{
        background: rgba(33, 150, 83, 0.10);
        color: #219653;
    }
    .badge-soft-danger{
        background: rgba(222, 53, 53, 0.10);
        color: #DE3535;
    }
.form-control {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
}
.form-control.is-invalid {
  border-color: #ff3d60 !important;
}
.form-control.is-valid {
  border-color: #1cbb8c;
}
.textarea-input {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 18px 25px;
}
.refund-reason .dropdown-toggle{
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid #DCDAD5;
}
.refund-reason-item {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
  max-height: 50vh;
  overflow-y: scroll;
}
.refund-reason-item a {
  height: 44px;
}
.collapse-body-table-head-col {
  color: rgba(0, 0, 0, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  margin-top: auto;
  margin-bottom: auto;
}
.collapse-body-table-col {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  margin-top: auto;
  margin-bottom: auto;
}
ul, li{
  list-style: none;
  padding: 0;
}
.time-line-container{
  padding-bottom: 20px;
  border-left: 1px solid rgba(220, 218, 213, 0.50);
  position: relative;
  padding-left: 20px;
  margin-left: 5px;
  &:last-child{
    border-left: 1px solid transparent;
    padding-bottom: 0;
  }
}
.time-line-heading{
    color: black;
    font-size: 12px;
    line-height: 16px;

}
.time-line-sub-heading{
    color: rgba(0, 0, 0, 0.50);
    font-size: 10px;
    font-weight: 325;
    line-height: 12px;
    margin-bottom: 0px;
}
.svg-container{
    position: absolute;
    left: -6px;
    z-index: 1;
    background: #fff;
    top: -1px;
    padding-bottom: 3px;
}
</style>